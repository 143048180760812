.accordion {
  list-style-type: none;
  margin: 0 0 16px 0;
  padding: 0 0 16px 0;
  border-radius: 7px;

  @media screen and (max-width: 400px) {
    padding: 0;
    margin: 0;
  }
}