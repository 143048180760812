.sectionLayout {
  //padding-top: 100px;

  margin-top: -30px;

  .container {
    width: 85%;
    max-width: 1600px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 85%;
  }
}

@media screen and (max-width: 700px) {
  .sectionLayout {
    padding-top: 100px;
  }
}

@media screen and (max-width: 400px) {
  .sectionLayout {
    padding-top: 50px;
  }
}