// variables
$color-site-background: #2b67fc;

$color-main-blue: #0C94FF;
$color-dark-blue: #193661;

$text-color: #fff;
$text-color-dark: #57729A;

$card-color-background: rgba(255, 255, 255, 0.6);
//$card-color-background: #f6f6f8;

// adaptive
$section-title-xl: 60px;
$section-title-l: 44px;
$section-title-m: 36px;
$section-title-s: 40px;

