@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.speakerCard {
  width: 100%;
  height: 100%;
  background: $card-color-background;

  border-radius: 25px;
  @include card-blue-shadow;

  transition: 1s;

  .content {
    padding: 40px;

    .portrait {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 200px;
      height: auto;

      border-radius: 50px;

    }

    .textWrapper {
      padding-top: 30px;

      .name {
        @include bold-font-style(22px);
        color: $color-dark-blue;

        text-align: center;
      }

      .post {
        @include regular-font-style(16px);
        line-height: 1.4;
        color: $text-color-dark;

        padding-top: 20px;

        text-align: center;
      }
    }
  }
}
