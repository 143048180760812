@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.start {
  .columns {
    display: flex;
    justify-content: space-between;
    height: auto;

    .textColumn {
      width: 67%;
      height: 100%;

      align-content: center;


      .textWrapper {
        .title {
          text-align: center;

          line-height: 1.3;

          .forum {
            color: $color-dark-blue;
          }

          @include bold-font-style(60px);
          color: $color-main-blue;

          @media screen and (max-width: 900px) {
            font-size: 48px;
          }
          @media screen and (max-width: 450px) {
            font-size: 40px;
            text-align: center;
          }
          @media screen and (max-width: 380px) {
            font-size: 34px;
            text-align: center;
          }
          @media screen and (max-width: 330px) {
            font-size: 30px;
            text-align: center;
          }
        }

        .text {
          padding-top: 30px;
          text-align: center;

          @include regular-font-style(20px);
          color: $color-dark-blue;
          line-height: 1.4;

          @media screen and (max-width: 900px) {
            font-size: 18px;
          }
        }

        .date, .place {
          margin-top: 20px;

          @include bold-font-style(60px);
          color: $color-dark-blue;
          text-align: center;
        }

        .place {
          font-size: 33px;
          line-height: 1.3;
        }

        .btnWrapper {
          margin-top: 30px;
          display: flex;

          .btn {
            width: 30%;
            margin: 0 auto;

            @media screen and (max-width: 1650px) {
              width: 40%;
            }

            @media screen and (max-width: 1480px) {
              width: 50%;
            }
            @media screen and (max-width: 990px) {
              width: 100% !important;
            }
          }
        }
      }

    }

    .widgetColumn {
      width: 30%;

      .widget {
        width: 100%;
        height: 100%;

        background-color: $card-color-background;
        @include card-blue-shadow;
        border-radius: 25px;

        @media screen and (max-width: 1290px) {
          align-content: center;
        }

        .content {
          padding: 20px;

          .price {
            align-content: center;
            @include bold-font-style(70px);
            color: $color-dark-blue;

            letter-spacing: -7px;
            text-align: center;
            padding: 10px 0 40px;

            @media screen and (max-width: 1730px) {
              font-size: 3.6rem;
            }

            @media screen and (max-width: 1480px) {
              font-size: 3.5rem;
            }

            @media screen and (max-width: 1350px) {
              font-size: 3.1rem;
            }

            @media screen and (max-width: 1175px) {
              font-size: 2.7rem;
            }

            @media screen and (max-width: 1000px) {
              font-size: 4rem;
            }

            @media screen and (max-width: 520px) {
              font-size: 3rem;
            }
            @media screen and (max-width: 350px) {
              font-size: 2.5rem;
              letter-spacing: -5px;
            }
          }

          .misha {
            height: 600px;
            width: auto;
            display: block;
            margin: 0 auto;

            @media screen and (max-width: 1290px) {
              width: 100%;
              height: auto;
            }

            @media screen and (max-width: 1000px) {
              height: 400px;
              width: auto;
            }

            @media screen and (max-width: 600px) {
              height: 400px;
              width: auto;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .start {
    .columns {
      .textColumn {
        .textWrapper {
          .btnWrapper {
            .btn {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .start {
    .columns {
      display: block;

      .textColumn, .widgetColumn {
        width: 100%;

        .textWrapper {
          .btnWrapper {
            .price {
              padding-left: 10%;
            }
          }
        }
      }

      .widgetColumn {
        margin-top: 40px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .start {
    .columns {
      display: block;

      .textColumn {

        .textWrapper {
          .btnWrapper {
            display: block;

            .btn {
              width: 100%;
            }

            .price {
              padding-left: 0;
              margin-top: 25px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}