@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.partnerCard {
  width: 100%;
  height: 100%;

  background: #fff;
  border-radius: 25px;
  @include card-blue-shadow;

  transition: .7s;

  align-content: center ;

  .logo {
    display: block;
    width: 100%;

    margin: 0 auto;
    padding: 20px;
    max-width: 400px;
  }
}

.partnerCard:hover {
  transform: scale(0.9);
  cursor: pointer;
}