@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.regBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  outline: none;
  border: none;

  background-color: $color-main-blue;
  padding: 20px 40px 20px 40px;
  border-radius: 25px;


  @include bold-font-style(28px);
  color: $text-color;

  @media screen and (max-width: 850px) {
    font-size: 16px;
  }

  transition: .7s;

  .btnText {
    align-content: center;
    padding-right: 15px;
  }

  .icon {
    width: 40px;
    height: 40px;
  }

}

.regBtn:hover {
  background-color: #1176c8;
  cursor: pointer;
}