@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

$about-company-column-w: 300px;

.footer {
  margin-top: 150px !important;

  @media screen and (max-width: 650px) {
    margin-top: 50px !important;
  }

  border-radius: 25px;
  margin-bottom: 30px;

  background-color: #fff;

  @include card-blue-shadow;

  .columns {
    display: flex;
    justify-content: space-between;

    .column {
      width: 30%;
      background-color: $text-color;
      border-radius: 25px;

      .aboutCompany {
        padding: 30px 0 20px 0;

        @media screen and (max-width: 900px) {
          padding-top: 0;
        }

        .logo {
          display: block;
          width: $about-company-column-w;
          height: auto;
          margin: 0 auto;
        }

        .socials {
          padding-top: 50px;
          display: flex;
          justify-content: space-around;

          width: $about-company-column-w;
          margin: 0 auto;

          .socialItem {
            display: block;

            .socialIcon {
              width: 50px;
            }
          }
        }

      }

      .menu {
        padding: 20px 0 20px 0;

        @media screen and (max-width: 900px) {
          padding-top: 0;
        }
      }

      .menuItem {
        display: block;

        @include regular-font-style(20px);
        color: $color-dark-blue;
        padding: 12px 0 12px 0;
        transition: .7s;

        @media screen and (max-width: 340px) {
          font-size: 16px;
        }
      }

      .menuItem:hover {
        color: $color-main-blue;
      }
    }

    .org {
      padding: 20px 0 20px 0;

      .title {
        @include regular-font-style(20px);
        color: $color-dark-blue;
        padding-bottom: 10px;

        @media screen and (max-width: 1250px) {
          font-size: 1.3rem;
        }
      }

      .orgContacts {
        .orgContact {
          display: block;
          padding-bottom: 10px;

          @include regular-font-style(20px);
          color: $color-dark-blue;

          transition: .7s;
        }

        .orgContact:hover {
          color: $color-main-blue;
        }
      }

      .credentials {
        .credential {
          @include regular-font-style(20px);
          color: $color-dark-blue;

          padding-bottom: 10px;
        }
      }

      .remark {
        padding-top: 50px;

        @include regular-font-style(18px);
        color: $color-dark-blue;
        text-align: center;
      }
    }
  }
}


@media screen and (max-width: 1250px) {
  .footer {
    .columns {
      .column {
        .aboutCompany {
          .logo, .socials {
            width: 90%;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .footer {
    .columns {
      padding: 20px;

      .column {
        width: 32.5%;
      }
    }
  }
}

@media screen and (max-width: 870px) {
  .footer {
    width: 70%;
    margin: 0 auto;

    .columns {
      display: block;
      padding: 30px;

      .column {
        margin-top: 25px;
        width: 100%;
      }
    }
  }
}


@media screen and (max-width: 480px) {
  .footer {
    width: 100%;
  }
}