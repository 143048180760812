.startSectionLayout {
  padding-top: 150px;

  .container {
    width: 85%;
    max-width: 1600px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 85% !important;
  }
}
