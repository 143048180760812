@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.program {

  .columns {
    display: flex;
    justify-content: space-between;

    .accColumn {
      width: 100%;
    }
  }

  .regBtn {
    margin-top: 0;
  }
}

@media screen and (max-width: 1650px) {
  .program {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1520px) {
  .program {
    margin-top: 50px;

  }
}

@media screen and (max-width: 1000px) {
  .program {
    .columns {
      display: block;

      .widgetColumn {
        width: 100%;

        .widget {
          height: auto;

          .content {
            .btn {
              display: none;
            }
          }
        }
      }

      .accColumn {
        margin-top: 15px;
        width: 100%;
      }
    }
  }
}