@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";

.accordionItem {
  margin-bottom: 16px;
  border-radius: 7px;

  .opened {
    background-color: #C3E1F9 !important;
  }

  .accordionHeader {
    @include card-blue-shadow;

    position: relative;
    width: 100%;
    padding: 20px 60px 20px 30px;

    background-color: $card-color-background;
    border: 0;

    border-radius: 25px;

    @include regular-font-style(25px);
    color: $color-dark-blue;
    text-align: left;
    cursor: pointer;

    @media screen and (max-width: 450px) {
      font-size: 18px !important;
    }
    @media screen and (max-width: 350px) {
      font-size: 14px !important;
    }

    .time {
      @include regular-font-style(18px);
      padding-bottom: 5px;
      color: $text-color-dark;
    }

    .accordionArrow {
      position: absolute;
      top: 50%;
      right: 20px;

      display: block;
      width: 40px;
      height: 40px;

      transform: translateY(-50%);
    }

    .accordionArrow.activeArrow {
      filter: invert(45%) sepia(99%) saturate(2606%) hue-rotate(188deg) brightness(97%) contrast(107%);
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .accordionCollapse {
    height: 0;
    overflow: hidden;
    transition: height 0.3s;

    .accordionBody {
      padding: 30px 30px 30px 30px;
      background-color: #C3E1F9;
      border-radius: 7px;

      .speakerCard {
        display: flex;
        justify-content: space-around;
        width: 80%;
        margin: 0 auto;

        @media screen and (max-width: 1200px) {
          width: 98%;
          justify-content: space-between;
        }

        .textWrapper {
          align-content: center;
          width: 100%;

          .name {
            @include bold-font-style(28px);
            color: $color-dark-blue;

            @media screen and (max-width: 330px) {
              font-size: 26px;
            }
          }

          .post {
            @include regular-font-style(18px);
            line-height: 1.4;
            padding-top: 15px;
          }
        }
      }
    }
  }

  .accordionCollapse.open {
    margin-top: 15px;
  }
}

@media screen and (max-width: 680px) {
  .accordionItem {
    .accordionCollapse {
      .accordionBody {
        .speakerCard {
          display: block;
          width: 100%;

          .portrait {
            display: block;
            margin: 0 auto;
          }

          .textWrapper {
            width: 100%;
            text-align: center;
            margin-top: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .accordionItem {
    .accordionCollapse {
      .accordionBody {
        .speakerCard {
          display: block;

          .portrait {
            width: 100%;
            height: auto;
          }

          .textWrapper {

          }
        }
      }
    }
  }
}












