.partners {
  margin-top: 70px;

  .columns {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    .column {
      width: 32%;
    }
  }
}

@media screen and (max-width: 600px) {
  .partners {
    .columns {
      display: block;

      .column {
        width: 100%;
        margin-top: 25px;
      }
    }
  }
}